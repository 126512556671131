import React, {useState} from 'react';
import s from './header.module.scss'
import SvgSelector from "../SvgSelector";
import {useMediaQuery} from "@mui/material";

const Header = () => {
  const isMobile = useMediaQuery(`(max-width: 480px)`);
  const [burgerIsOpen, setBurgerIsOpen] = useState(false)
  if (!isMobile) {
    return ( //------------------------desktop
      <header>
        <div className={s.left}>
          <a href={'https://www.rzd.ru/'} className={s.rzd}>
            <SvgSelector svg={'rzd'}/>
          </a>
          <div className={s.and}>
            <SvgSelector svg={'and'}/>
          </div>

          <a href={'https://pushkeen.ru/'} className={s.pushkeen}>
            <SvgSelector svg={'pushkeen'}/>
          </a>
        </div>
        <div style={{width: '680px'}}/>
        <div className={s.right}>
          <a href={'#blockSecond'}><p>настольная игра</p></a>
          <a href={'#blockThird'}><p>комикс</p></a>
          <a href={'#blockFourth'}><p>мобильная игра</p></a>
        </div>
      </header>
    );
  } else {
    return ( //------------------------mobile
      <>
        <header>
          <div className={s.left}>
            <a href={'https://www.rzd.ru/'} className={s.rzd}>
              <SvgSelector svg={'rzd'}/>
            </a>
            <div className={s.and}>
              <SvgSelector svg={'and'}/>
            </div>

            <a href={'https://pushkeen.ru/'} className={s.pushkeen}>
              <SvgSelector svg={'pushkeen'}/>
            </a>
          </div>
          <button
            className={s.right}
            onClick={() => setBurgerIsOpen(!burgerIsOpen)}
          >
            <SvgSelector svg={'burger'}/>
          </button>
        </header>
        <div
          className={s.burger}
          style={{
            height: burgerIsOpen ? '100vh' : '0vh',
            backgroundColor: burgerIsOpen ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0)',
            backdropFilter: burgerIsOpen ? 'blur(10px)' : 'blur(0px)',

          }}
        >
          <div
            className={s.links}
            style={{
              height: burgerIsOpen ? '140vw' : '0vw',
              gap: burgerIsOpen ? '8.53vw' : '0vw',
              padding: burgerIsOpen ? '20vw 4vw' : '0vw 0vw',
            }}
          >

              <button
                className={s.close}
                onClick={() => setBurgerIsOpen(false)}
              >
                <SvgSelector svg={'close'}/>
              </button>


            <a
              href={'#blockSecond'}
              onClick={() => setBurgerIsOpen(!burgerIsOpen)}
            >
              <h2>настольная игра</h2>
            </a>
            <div className={s.line}/>
            <a
              href={'#blockThird'}
              onClick={() => setBurgerIsOpen(!burgerIsOpen)}
            >
              <h2>комикс</h2>
            </a>
            <div className={s.line}/>
            <a
              href={'#blockFourth'}
              onClick={() => setBurgerIsOpen(!burgerIsOpen)}
            >
              <h2>мобильная игра</h2>

                <a
                  className={s.download_link}
                  href={'https://play.google.com/store/apps/details?id=com.pushkeen.traindetective'}
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <SvgSelector svg={'google'}/>
                  <p>Download on Google Play</p>
                </a>
                <a
                  className={s.download_link}
                  href={'https://apps.apple.com/ru/app/traindetective-visual-novel/id6470943390'}
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <SvgSelector svg={'apple'}/>
                  <p>Download on App Store</p>
                </a>

            </a>
          </div>
        </div>
      </>

    );
  }

};

export default Header;