import React from 'react';
import s from './BlockFirst.module.scss'
import img from '../../assets/blockFirst.png'
import title from '../../assets/blockFirst_title.png'
import SvgSelector from "../SvgSelector";

const BlockFirst = () => {
  return (
    <div className={s.block}>
      <div className={s.block_wrapper}>
        <div className={s.left}>
          <h1>В дороге ещё интереснее</h1>

          <div className={s.row1}>
            <a href={'#blockSecond'} className={s.row1_block}>
              <h4>Настольная игра</h4>
              <p>«Города и поезда»</p>
            </a>
            <a href={'#blockThird'} className={s.row1_block}>
              <h4>Комикс</h4>
              <p>«Хилков. Легенды <br/> железных дорог России»</p>
            </a>
          </div>

          <a className={s.row2} href={'#blockFourth'}>
            <div className={s.row2_block1}>
              <h4>Мобильная игра</h4>
              <div className={s.row2_block1_title}>
                <img src={title} alt={""}/>
                <p>«TrainDetective: <br/>  visual novel»</p>
              </div>
            </div>
            <div className={s.row2_block2}>
              <a
                className={s.row2_block2_link}
                href={'https://play.google.com/store/apps/details?id=com.pushkeen.traindetective'}
                target={'_blank'}
                rel="noreferrer"
              >
                <SvgSelector svg={'google'}/>
                <p>Download on Google Play</p>
              </a>
              <a
                className={s.row2_block2_link}
                href={'https://apps.apple.com/ru/app/traindetective-visual-novel/id6470943390'}
                target={'_blank'}
                rel="noreferrer"
              >
                <SvgSelector svg={'apple'}/>
                <p>Download on App Store</p>
              </a>
            </div>
          </a>
        </div>
        <div className={s.right}>
          <img src={img} alt={""}/>
        </div>
      </div>
    </div>
  );
};

export default BlockFirst;