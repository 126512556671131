import React, {useState} from 'react';
import {useMediaQuery} from "@mui/material";
import s from "./blockThrid.module.scss";
import img from "../../assets/blockFhird.png";
import imgMob from "../../assets/blockFhirdMobile.png";
import SvgSelector from "../SvgSelector";

const BlockThird = () => {
    const [selectedText, setSelectedText] = useState(1)
    const [burgerIsOpen, setBurgerIsOpen] = useState(false)
    const isMobile = useMediaQuery(`(max-width: 480px)`);
    return (
        <div className={s.block} id={'blockThird'}>
            <div className={s.block_wrapper}>
                <div className={s.right}>
                    <h2>Комикс «Хилков. Легенды железных дорог России»</h2>
                    {isMobile &&
                        <img src={imgMob} alt={""}/>
                    }
                    {isMobile ? (
                        <>
                            <div style={{height: '5vw'}}/>
                            <div
                                className={s.buttons}
                                style={{
                                    height: burgerIsOpen ? '46.67vw' : '10vw'
                                }}
                            >
                                <button
                                    onClick={() => setBurgerIsOpen(!burgerIsOpen)}
                                    style={{
                                        background: '#101010',
                                        color: '#FFF',
                                    }}
                                >
                                    <h5>
                                        {
                                            selectedText === 1 ?
                                                'О проекте' :
                                                selectedText === 2 ?
                                                    'История' :
                                                    'Комикс'
                                        }
                                    </h5>
                                    <div
                                        style={{
                                            height: '100%',
                                            transition: '0.5s ease',
                                            transformOrigin: "50% 50%",
                                            transform: burgerIsOpen ? 'rotate(180deg)' : 'rotate(0deg)'
                                        }}
                                    >
                                        <SvgSelector svg={'arrow'}/>
                                    </div>

                                </button>
                                <button onClick={() => {
                                    setSelectedText(1)
                                    setBurgerIsOpen(!burgerIsOpen)
                                }}>
                                    <h5>О проекте</h5>
                                </button>
                                <button onClick={() => {
                                    setSelectedText(2)
                                    setBurgerIsOpen(!burgerIsOpen)
                                }}>
                                    <h5>История</h5>
                                </button>
                                <button onClick={() => {
                                    setSelectedText(3)
                                    setBurgerIsOpen(!burgerIsOpen)
                                }}>
                                    <h5>Комикс</h5>
                                </button>
                            </div>
                        </>

                    ) : (
                        <div className={s.buttons}>
                            <button
                                onClick={() => setSelectedText(1)}
                                style={{
                                    background: selectedText === 1 && '#101010',
                                    color: selectedText === 1 && '#FFF',
                                }}
                            >
                                <h5>О проекте</h5>
                            </button>
                            <button
                                onClick={() => setSelectedText(2)}
                                style={{
                                    background: selectedText === 2 && '#101010',
                                    color: selectedText === 2 && '#FFF',
                                }}
                            >
                                <h5>История</h5>
                            </button>
                            <button
                                onClick={() => setSelectedText(3)}
                                style={{
                                    background: selectedText === 3 && '#101010',
                                    color: selectedText === 3 && '#FFF',
                                }}
                            >
                                <h5>Комикс</h5>
                            </button>
                        </div>
                    )}

                    {selectedText === 1 &&
                        <div className={s.text}>
                            <h3>О проекте</h3>
                            <p>
                                Хилков. Легенды железных дорог России — комикс, основанный на биографии Михаила
                                Ивановича Хилкова — одного из ярчайших министров путей сообщения Российской империи.
                            </p>
                        </div>
                    }
                    {selectedText === 2 &&
                        <div className={s.text}>
                            <h3>История</h3>
                            <p>
                                Судьба Михаила Ивановича полна неожиданных поворотов, острых конфликтов и трудных
                                решений. Он мечтал изучить железнодорожное дело «до последней гайки», поэтому отправился
                                в Америку, где прошел долгий путь от простого рабочего до заведующего службой подвижного
                                состава. Всё для того, чтобы затем вернуться домой и изменить судьбу целой страны.
                            </p>
                        </div>
                    }
                    {selectedText === 3 &&
                        <div className={s.text}>
                            <h3>Комикс</h3>
                            <p>
                                В комиксе с помощью визуальных элементов отражены важнейшие события истории развития
                                железных дорог
                                второй половины XIX века. А реалистичные цвета, использованные в иллюстрациях, передают
                                динамику сюжета
                                и эмоции.
                            </p>
                        </div>
                    }
                </div>
                {!isMobile &&
                    <div className={s.left}>
                        <img src={img} alt={""}/>
                    </div>
                }

            </div>

        </div>
    );
};

export default BlockThird;