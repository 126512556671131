import Header from "./components/Header/Header";
import BlockFirst from "./components/BlockFirst/BlockFirst";
import BlockSecond from "./components/BlockSecond/BlockSecond";
import BlockThird from "./components/BlockThird/BlockThird";
import BlockFourth from "./components/BlockFourth/BlockFourth";
import './styles/styles.scss'

function App() {
  return (
    <>
      <Header/>
      <BlockFirst/>
      <BlockSecond/>
      <BlockThird/>
      <BlockFourth/>
    </>
  );
}

export default App;
