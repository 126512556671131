import React, {useState} from 'react';
import s from './blockSecond.module.scss'
import img from '../../assets/blockSecond.png'
import imgMob from '../../assets/blockSecondMobile.png'
import {useMediaQuery} from "@mui/material";
import SvgSelector from "../SvgSelector";

const BlockSecond = () => {
    const [selectedText, setSelectedText] = useState(1)
    const [burgerIsOpen, setBurgerIsOpen] = useState(false)
    const isMobile = useMediaQuery(`(max-width: 480px)`);
    return (
        <div className={s.block} id={'blockSecond'}>
            <div className={s.block_wrapper}>
                {!isMobile &&
                    <div className={s.left}>
                        <img src={img} alt={""}/>
                    </div>
                }
                <div className={s.right}>
                    <h2>Настольная игра <br/> «Города и поезда»</h2>
                    {isMobile &&
                        <div className={s.left}>
                            <img src={imgMob} alt={""}/>
                        </div>
                    }
                    {isMobile ? (
                        <>
                            <div style={{height: '5vw'}}/>
                            <div
                                className={s.buttons}
                                style={{
                                    height: burgerIsOpen ? '46.67vw' : '10vw'
                                }}
                            >
                                <button
                                    onClick={() => setBurgerIsOpen(!burgerIsOpen)}
                                    style={{
                                        background: '#DF1F25',
                                        color: '#FFF',
                                    }}
                                >
                                    <h5>
                                        {
                                            selectedText === 1 ?
                                                'О проекте' :
                                                selectedText === 2 ?
                                                    'Наполнение игры' :
                                                    'Ход игры'
                                        }
                                    </h5>
                                    <div
                                        style={{
                                            height: '100%',
                                            transition: '0.5s ease',
                                            transformOrigin: "50% 50%",
                                            transform: burgerIsOpen ? 'rotate(180deg)' : 'rotate(0deg)'
                                        }}
                                    >
                                        <SvgSelector svg={'arrow'}/>
                                    </div>

                                </button>
                                <button onClick={() => {
                                    setSelectedText(1)
                                    setBurgerIsOpen(!burgerIsOpen)
                                }}>
                                    <h5>О проекте</h5>
                                </button>
                                <button onClick={() => {
                                    setSelectedText(2)
                                    setBurgerIsOpen(!burgerIsOpen)
                                }}>
                                    <h5>Наполнение игры</h5>
                                </button>
                                <button onClick={() => {
                                    setSelectedText(3)
                                    setBurgerIsOpen(!burgerIsOpen)
                                }}>
                                    <h5>Ход игры</h5>
                                </button>
                            </div>
                        </>

                    ) : (
                        <div className={s.buttons}>
                            <button
                                onClick={() => setSelectedText(1)}
                                style={{
                                    background: selectedText === 1 && '#DF1F25',
                                    color: selectedText === 1 && '#FFF',
                                }}
                            >
                                <h5>О проекте</h5>
                            </button>
                            <button
                                onClick={() => setSelectedText(2)}
                                style={{
                                    background: selectedText === 2 && '#DF1F25',
                                    color: selectedText === 2 && '#FFF',
                                }}
                            >
                                <h5>Игра</h5>
                            </button>
                            <button
                                onClick={() => setSelectedText(3)}
                                style={{
                                    background: selectedText === 3 && '#DF1F25',
                                    color: selectedText === 3 && '#FFF',
                                }}

                            >
                                <h5>Ход игры</h5>
                            </button>
                        </div>
                    )}
                    {selectedText === 1 &&
                        <div className={s.text}>
                            <h3>О проекте</h3>
                            <p>
                                «Города и поезда» — игра-викторина, которая знакомит игроков с городами России. Главная
                                цель игры — собрать самый длинный поезд из карточных вагонов с названиями городов. Чтобы
                                получить как можно больше очков Вам предстоит отвечать на вопросы и выигрывать дуэли.
                            </p>
                        </div>

                    }
                    {selectedText === 2 &&
                        <div className={s.text}>
                            <h3>Игра</h3>
                            <p>
                                В игре вы найдете 87 карт с яркими иллюстрациями: 81 карту типа «Вагон», каждая из
                                которых включает
                                название города и интересный факт о нём, дополненный особым заданием; 6 карт типа
                                «Локомотив», которые
                                знакомят игроков с фирменными поездами и добавляют в игру дополнительные условия.
                            </p>
                        </div>
                    }
                    {selectedText === 3 &&
                        <div className={s.text}>
                            <h3>Ход игры</h3>
                            <p>
                                Для участников разных возрастов в игре предусмотрены 3 уровня сложности: у каждого свои
                                правила. <br/><br/>
                                Первый уровень подойдет детям до 10 лет. Все, как в классической игре «Города», только в
                                карточной форме — необходимо выложить карту, название города на которой начинается на
                                последнюю букву города, написанного на предыдущей карте. Второй и третий уровень
                                подойдут для ребят постарше и взрослых, которые готовы блеснуть своими знаниями.
                            </p>
                        </div>
                    }

                </div>
            </div>

        </div>
    );
};

export default BlockSecond;