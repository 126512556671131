import React, {useState} from 'react';
import s from './blockFourth.module.scss'
import img from '../../assets/blockFourth.png'
import imgMob from '../../assets/blockFourthMobile.png'
import {useMediaQuery} from "@mui/material";
import SvgSelector from "../SvgSelector";

const BlockFourth = () => {
  const [selectedText, setSelectedText] = useState(1)
  const [burgerIsOpen, setBurgerIsOpen] = useState(false)
  const isMobile = useMediaQuery(`(max-width: 480px)`);
  const os = navigator.platform;
  //console.log(os);
  return (
    <div className={s.block} id={'blockFourth'}>
      <div className={s.block_wrapper}>
        {!isMobile &&
          <div className={s.left}>
            <img src={img} alt={""}/>
          </div>
        }
        <div className={s.right}>
          <h2>мобильная<br/>игра-новелла</h2>
          {isMobile &&
            <div className={s.left}>
              <img src={imgMob} alt={""}/>
            </div>
          }
          {isMobile ? (
            <>
              <div style={{height: '5vw'}}/>
              <div
                className={s.buttons}
                style={{
                  height: burgerIsOpen ? '46.67vw' : '10vw'
                }}
              >
                <button
                  onClick={() => setBurgerIsOpen(!burgerIsOpen)}
                  style={{
                    background: '#DF1F25',
                    color: '#FFF',
                  }}
                >
                  <h5>
                    {
                      selectedText === 1 ?
                        'О проекте' :
                        selectedText === 2 ?
                          'История' :
                          'Ход игры'
                    }
                  </h5>
                  <div
                    style={{
                      height: '100%',
                      transition: '0.5s ease',
                      transformOrigin: "50% 50%",
                      transform: burgerIsOpen ? 'rotate(180deg)' : 'rotate(0deg)'
                    }}
                  >
                    <SvgSelector svg={'arrow'}/>
                  </div>

                </button>
                <button onClick={() => {
                  setSelectedText(1)
                  setBurgerIsOpen(!burgerIsOpen)
                }}>
                  <h5>О проекте</h5>
                </button>
                <button onClick={() => {
                  setSelectedText(2)
                  setBurgerIsOpen(!burgerIsOpen)
                }}>
                  <h5>История</h5>
                </button>
                <button onClick={() => {
                  setSelectedText(3)
                  setBurgerIsOpen(!burgerIsOpen)
                }}>
                  <h5>Ход игры</h5>
                </button>
              </div>
            </>

          ) : (
            <div className={s.buttons}>
              <button
                onClick={() => setSelectedText(1)}
                style={{
                  background: selectedText === 1 && '#DF1F25',
                  color: selectedText === 1 && '#FFF',
                }}
              >
                <h5>О проекте</h5>
              </button>
              <button
                onClick={() => setSelectedText(2)}
                style={{
                  background: selectedText === 2 && '#DF1F25',
                  color: selectedText === 2 && '#FFF',
                }}
              >
                <h5>Наполнение</h5>
              </button>
              <button
                onClick={() => setSelectedText(3)}
                style={{
                  background: selectedText === 3 && '#DF1F25',
                  color: selectedText === 3 && '#FFF',
                }}

              >
                <h5>Ход игры</h5>
              </button>
            </div>
          )}
          {selectedText === 1 &&
            <div className={s.text}>
              <h3>О проекте</h3>
              <p>
                «TrainDetective» — детективная визуальная новелла с элементами квеста, разворачивающаяся
                на фоне событий начала XX века. Пользователь полностью погружен в исторические события —
                следуя по сюжету он разгадывает загадки и принимает решения, от которых зависит ход
                игры.
              </p>
            </div>

          }
          {selectedText === 2 &&
            <div className={s.text}>
              <h3>История</h3>
              <p>
                Мобильная игра связана с комиксом «Хилков. Легенды железных дорог России». Взяв за
                основу реальные
                исторические события начала XX века в России, мы предлагаем альтернативный взгляд на
                историю.
              </p>
            </div>
          }
          {selectedText === 3 &&
            <div className={s.text}>
              <h3>Ход игры</h3>
              <p>
                Всего за три дня пользователь должен раскрыть тайны пассажиров загадочного поезда,
                следующего в Китай.
                Погружаясь в текстовую историю, необходимо расследовать события, принимать решения,
                которые влияют на
                развитие сюжета. В новеллу также включены мини-игры, которые делают процесс прохождения
                более
                интерактивным и увлекательным.
              </p>
            </div>
          }
          {isMobile &&
            <a className={s.download} href={'https://apps.pushkeen.ru/RZD'}><h3>Скачать игру</h3></a>
          }
        </div>
      </div>

    </div>
  );
};

export default BlockFourth;